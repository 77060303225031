





















































































































































































import { Component, Vue } from 'vue-property-decorator'
import {
    apiPrintType,
    apiTemplateLists,
    apiAddPrint,
    apiEditPrint,
    apiPrintDetail
} from '@/api/application/print'
@Component
export default class PrintEdit extends Vue {
    /** S Data **/

    identity: Number = 1

    status: any = ''

    printType: Array<Object> = []

    printTemplate: Array<Object> = []

    // 打印机设置的数据
    printData: any = {
        name: '', //	是	string	打印机名称
        type: 1, //	是	integer	设备类型
        machine_code: '', //	是	string	终端号
        private_key: '', //	是	string	打印机密钥
        client_id: '', //	是	string	应用ID
        client_secret: '', //	是	string	应用密钥
        template_id: '请选择', //	是	integer	模板id
        print_number: 1, //是	integer	打印联数 范围：1到4
        auto_print: 1, //	是	integer	自动打印 0-否 1-是
        status: 1 //	是	integer	启动状态 0-否 1-是
    }

    // 表单验证
    rules: any = {
        name: [
            { required: true, message: '请输入打印机名称', trigger: 'blur' }
        ],
        type: [
            { required: true, message: '请选择设备类型', trigger: 'change' }
        ],
        machine_code: [
            { required: true, message: '请输入终端号', trigger: 'blur' }
        ],
        private_key: [
            { required: true, message: '请输入打印机密钥', trigger: 'blur' }
        ],
        client_id: [
            { required: true, message: '请输入应用ID', trigger: 'blur' }
        ],
        client_secret: [
            { required: true, message: '请输入应用密钥', trigger: 'blur' }
        ],
        template_id: [
            { required: true, message: '请选择模板', trigger: 'change' }
        ]
    }

    /** E Data **/

    /** S Methods **/
    newTemplate() {
        const text = this.$router.resolve('/print/edit_template')
        window.open(text.href, '_blank')
    }

    // 获取打印机类型数据
    async getPrintDetail() {
        const res = await apiPrintDetail({ id: this.identity })
        this.printData = res
    }

    getPrintTypeFunc() {
        apiPrintType({})
            .then((res: any) => {
                this.printType = res
            })
            .catch(() => {
                this.$message.error('数据初始化失败，请刷新重载！')
            })
    }

    // 获取小票模版数据
    getPrintTemplateFunc() {
        apiTemplateLists({})
            .then((res: any) => {
                this.printTemplate = res.lists
            })
            .catch(() => {
                this.$message.error('数据初始化失败，请刷新重载！')
            })
    }

    // 点击表单提交
    onSubmit(formName: string) {
        // 验证表单格式是否正确
        const refs = this.$refs[formName] as HTMLFormElement
        refs.validate((valid: boolean): any => {
            if (!valid) {
                return
            }
            if (!this.identity) {
                this.handlePrintAdd()
            } else {
                this.handlePrintEdit()
            }
        })
    }

    handlePrintAdd() {
        const params = this.printData
        apiAddPrint({ ...params }).then(() => {
            setTimeout(() => this.$router.go(-1), 500)
        })
    }

    // 编辑打印机
    handlePrintEdit() {
        const params = this.printData
        const id: number = this.identity as number
        apiEditPrint({ ...params, id }).then(() => {
            setTimeout(() => this.$router.go(-1), 500)
        })
    }

    /** E Methods **/

    /** S Life Cycle **/
    created() {
        const query: any = this.$route.query
        this.identity = query.id
        this.getPrintTypeFunc()
        this.getPrintTemplateFunc()
        if (this.identity) {
            this.getPrintDetail()
        }
    }
    /** E Life Cycle **/
}
